import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'mui-blox'
import { IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Conditional } from '~controls'
import { useConversation } from '../hooks/useConversation'
import BackIcon from 'mdi-react/ArrowBackIcon'
import MessageActionProvider from '../decorators/MessageActionProvider'
import ExconView from '../common/ExconView'
import Header from '../common/Header'
import Conversation from './Conversation'
import ConversationInput from './ConversationInput'
import Advert from '../common/Advert'
import { useExecuteLink } from '../hooks/useExecuteLink'
import { announce } from '@react-aria/live-announcer'
import { MESSAGE_DIRECTIONS } from '~constants'
import { useHighZoomDetection } from '../hooks/useHighZoomDetection'

const MessengerConversation = ({ conversationId, onModeChange, ready, hideFrame }) => {
  const classes = useStyles()

  const { isVeryHighZoom } = useHighZoomDetection(4)

  const [firstSentDate, setFirstSentDate] = useState(null)

  const { conversation, onSendMessage, isLoading, isFetching } = useConversation(conversationId, ready)
  const { executeLink } = useExecuteLink()

  const [exconUrl, setExconUrl] = useState('')
  const showExcon = Boolean(exconUrl)

  const handleSendMessage = post => {
    if (!firstSentDate) {
      setFirstSentDate(new Date())
    }
    onSendMessage(post)
  }

  const handlePostBack = post => {
    handleSendMessage(post)
  }

  const wasIsTypingAnnounced = useRef(false)

  useEffect(() => {
    if (!firstSentDate) return

    if (isLoading) {
      if (!wasIsTypingAnnounced.current) {
        wasIsTypingAnnounced.current = true
      }
      return
    }

    wasIsTypingAnnounced.current = false

    if (conversation) {
      const lastIncomingReversed = [...conversation]
        .reverse()
        .findIndex(item => item.direction === MESSAGE_DIRECTIONS.incoming)

      if (lastIncomingReversed <= -1) return

      const itemsToBeAnnounced = [...conversation].slice(-lastIncomingReversed)
      const notValid = itemsToBeAnnounced.some(item => item.direction === MESSAGE_DIRECTIONS.incoming)
      if (notValid) return

      let fullMessage = ''
      for (const [index, { message, messageTimestamp, direction }] of itemsToBeAnnounced.entries()) {
        const messageTime = new Date(messageTimestamp)
        const isBotResponse = direction === MESSAGE_DIRECTIONS.outgoing
        const isLatestMessage = messageTime.getTime() >= firstSentDate.getTime()

        if (isBotResponse && isLatestMessage) {
          const prefix = index === 0 ? 'Bot says ' : ''
          fullMessage += `${prefix}${message} `
        }
      }

      if (fullMessage) {
        announce(fullMessage, 'assertive')
      }
    }
  }, [firstSentDate, conversation, isLoading])

  const handleLink = async url => {
    const handleLocal = localUrl => {
      setExconUrl(localUrl)
      onModeChange && onModeChange('excon')
    }
    await executeLink(url, handleLocal, hideFrame)
  }

  const handleBack = () => {
    setExconUrl('')
    onModeChange && onModeChange('')
  }

  const controlButton = () => {
    if (!showExcon) return null
    return (
      <IconButton onClick={handleBack} size='small' aria-label='Back to conversation'>
        <BackIcon />
      </IconButton>
    )
  }

  return (
    <MessageActionProvider postBack={handlePostBack} linkTo={handleLink}>
      <Flex
        flexColumn
        height='100%'
        minHeight={isVeryHighZoom ? '100%' : 0}
        className={isVeryHighZoom ? classes.messengerConversationHighZoom : classes.messengerConversation}
      >
        <Conditional conditions={{ excon: showExcon, conversation: !showExcon }}>
          {{
            excon: () => <ExconView url={exconUrl} />,
            conversation: () => (
              <>
                {!hideFrame && <Header />}
                <Conversation conversation={conversation} isLoaded={ready && !isFetching} isFetching={isFetching} />
                <ConversationInput onSendMessage={handleSendMessage} />
                {!hideFrame && <Advert action={controlButton()} />}
              </>
            ),
          }}
        </Conditional>
      </Flex>
    </MessageActionProvider>
  )
}

MessengerConversation.propTypes = {
  conversationId: PropTypes.string.isRequired,
  ready: PropTypes.bool.isRequired,
  onModeChange: PropTypes.func,
  hideFrame: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
  messengerConversation: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
  },
  messengerConversationHighZoom: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: '100%',
    overflowY: 'auto',
  },
}))

export default MessengerConversation
