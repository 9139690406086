import { useCallback, useEffect, useState } from 'react'
import useSWR from 'swr'
import { useDataContext } from '../../_controls'
import { MESSAGE_DIRECTIONS, MESSAGE_SOURCES } from '../../../../constants'
import { useBotMessageListener } from './useBotMessageListener'
import { config } from '~config'
import { useGet, usePost, useRequest } from '~brix/connectors/fetch'
import { useFlags } from 'flagsmith/react'

export const useConversation = (conversationId, ready) => {
  const [loading, setIsLoading] = useState(false)
  const eventId = useDataContext('eventId')
  const flags = useFlags(['cloud-function_messenger-api'])
  const cloudApiFeatureFlag = flags['cloud-function_messenger-api'].enabled
  const { get } = useGet()
  const { post } = usePost()

  const getUrl = cloudApiFeatureFlag
    ? `${config.firebase.exconPlaceMessengerApi}/api/v2/messenger/event/${eventId}/id/${conversationId}`
    : `/messenger/event/${eventId}/id/${conversationId}`
  const { request: requestConversation, isRequesting: isGetting } = useRequest(getUrl, 'GET')

  const sendUrl = cloudApiFeatureFlag
    ? `${config.firebase.exconPlaceMessengerApi}/api/v2/messenger/event/${eventId}/id/${conversationId}`
    : `/messenger/event/${eventId}/id/${conversationId}`
  const { request: postMessage } = useRequest(sendUrl, 'POST')

  const {
    data: conversation,
    mutate,
    isValidating,
  } = useSWR(
    ready ? getUrl : null,
    async () => {
      const result = cloudApiFeatureFlag ? await requestConversation() : await get(getUrl)
      sessionStorage.setItem(getStorageKey(conversationId), JSON.stringify(result))
      return result
    },
    { revalidateOnFocus: false },
  )

  const addMessage = useCallback(
    async message => {
      await mutate(current => {
        return [...current, message]
      }, false)
    },
    [mutate],
  )

  useBotMessageListener(conversationId, addMessage)

  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => setIsLoading(false), 3000)

      return () => {
        clearInterval(timer)
      }
    }
  }, [loading])

  const onSendMessage = useCallback(
    async text => {
      const message = {
        message: text,
        direction: MESSAGE_DIRECTIONS.incoming,
        source: MESSAGE_SOURCES.endUser,
      }
      const result = cloudApiFeatureFlag ? await postMessage({ message: text }) : post(sendUrl, { message: text })
      message.messageTimestamp = result.received

      await addMessage(message)
      setIsLoading(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate],
  )

  return {
    conversation,
    isFetching: conversation === undefined || isGetting,
    isLoading: isValidating || loading,
    onSendMessage,
  }
}

const getStorageKey = id => `conversation_${id}`
